import social1 from '../assets/social1.png';
import social2 from '../assets/social2.png';
import social3 from '../assets/social3.png';

const socials = [
  { id: 1, image: social1, link: 'https://www.pinterest.com/' },
  { id: 2, image: social2, link:  'https://www.instagram.com/'},
  { id: 3, image: social3, link: 'https://www.facebook.com/' },
 ];

export default socials;
